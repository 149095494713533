<template>
  <div>
    <v-alert
      class="fixed-center"
      border="bottom"
      colored-border
      type="warning"
      elevation="2"
    >
      Aradığınız servis bulunamadı veya aktif değil. Servis sağlayıcınızla
      görüşün.
    </v-alert>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
