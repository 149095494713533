import axios from "axios";
import { baseUrl } from "../constants/urls";

const requestHeaders = {
  "Ocp-Apim-Subscription-Key": "f5a019f020aa4569bae71d1b3eedc3d1",
};

export const getRoutesAsync = async ({ statrtLocation, endLocation }) => {
  const placeId = localStorage.placeId;
  const disabled = localStorage.getItem("disabled") === "true" ? "?d=1" : "";

  try {
    const response = await axios.get(
      `${baseUrl}/webn/r/${placeId}/${statrtLocation}/${endLocation}${disabled}`,
      {
        headers: requestHeaders,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPlaceConfigAsync = async () => {
  const placeId = localStorage.placeId;
  try {
    const response = await axios.get(`${baseUrl}/webn/config/${placeId}`, {
      headers: requestHeaders,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
