import Vue from "vue";
import VueI18n from "vue-i18n";
import { tr, en, ar, hrv } from "./languages";

Vue.use(VueI18n);

const messages = {
  tr,
  en,
  ar,
  hrv,
};

// let urlParams = new URLSearchParams(window.location.search);

// const hasLanguageParams = urlParams.has("language");
// const languageParams = urlParams.get("language");

// if (hasLanguageParams) {
//   localStorage.setItem("language", languageParams);
// } else {
//   localStorage.setItem("language", "tr");
// }

const i18n = new VueI18n({
  locale: "tr",
  messages,
  silentTranslationWarn: true,
  fallbackLocale: "tr",
});

export default i18n;
